<template>
  <div>
    <div class="row align-content-center">
      <div class="col-12">
        <form @submit.prevent="salvar()">
          <label>Especialidade</label>

          <b-input
            type="text"
            v-model="especialidades.especialidade"
            placeholder="Especialidade"
            :state="validateState('especialidade')"
          />

          <b-form-invalid-feedback
            >Informe uma especialidade válida</b-form-invalid-feedback
          >
        </form>
      </div>
    </div>
   
    <div class="row">
      <div class="col-12">
        <hr class="mb-2" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 d-flex justify-content-end align-items-end  ">
        <b-button
          size="md"
          class="mr-2"
          variant="primary"
          @click="salvar()"
          :disabled="loading"
        >
          <span v-if="!loading"><i class="fa fa-save"></i> Salvar</span>
          <span v-if="loading"
            ><b-spinner small size="sm" grow /> Salvando...</span
          >
        </b-button>
        <b-button size="md" variant="secondary" @click="fecharModal()">
          <i class="fa fa-times"></i> Cancelar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Validadores from "@/helpers/Validadores";
import EmpresasLib from "@/libs/EmpresasLib";
import EspecialidadesLib from "@/libs/EspecialidadesLib";

export default {
  props: {
    onSaveDone: Function,
    especialidade_edit: Object,
  },
  data() {
    return {
      especialidades: {
        id: undefined,
        especialidade: "",
      },
      empresas: [],
      hasErrors: [],
      loading: false,
    };
  },
  async mounted() {
    this.clearErrors();
    this.empresas = await EmpresasLib.getEmpresasGrupo();

    if (this.especialidade_edit) {
      this.especialidades = this.especialidade_edit;
    }
  },
  methods: {
    fecharModal(){
      this.$bvModal.hide('modal-especialidades')
    },
    validateState(campo) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    async validar() {
      this.clearErrors();

      this.addOrUpdateError(
        "especialidade",
        !Validadores.string.IsNullOrEmpty(this.especialidades.especialidade)
      );

      this.addOrUpdateError("empresa_id", this.especialidades.empresa_id > 0);

      await this.$nextTick();

      return this.hasErrors.filter((x) => x.state == true).length >= 1;
    },
    showErrorSave() {
      this.$swal.fire({
        title: "Erro ao salvar!",
        icon: "error",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      });
    },
    async salvar() {
      this.loading = true;
      await this.$nextTick();
      try {
        const validado = await this.validar();

        if (validado === true) {
          const res = await EspecialidadesLib.store(this.especialidades);

          if (res && res.success) {
            this.$swal.fire({
              title: "Concluído!",
              icon: "success",
              toast: true,
              position: "top-end",
              timer: 1500,
              showConfirmButton: false,
              timerProgressBar: true,
            });
            this.$emit('especialidadeCadastrada')
            if (this.onSaveDone) {
              this.onSaveDone();
            }
          } else {
            this.showErrorSave();
          }
        }
      } catch (e) {
        console.log("error on save", e);
        this.showErrorSave();
      }

      this.loading = false;
    },
  },
};
</script>

<style></style>
