<template>
  <div>
    <div class="row mt-3 mt-4 ">
      <b-modal
        id="modal-especialidades"
        size="lg"
        title="Cadastrar Especialidade"
        no-fade
        hide-footer
      >
        <EspecialidadesForm
          @especialidadeCadastrada="carregaEspecialidades()"
        />
      </b-modal>
      <!-- <div class="col-12 col-sm-6">
        <h4 @click="visible = !visible" style="cursor: pointer;">
          Especialidades
          <span
            v-b-popover.hover="popoverConfig"
            :class="[
              visible
                ? 'fas fa-caret-square-up align-bottom text-danger'
                : 'fas fa-caret-square-down align-bottom text-danger',
            ]"
          ></span>
        </h4>
      </div> -->

      <!-- <div class="col-12 col-sm-6">
        <Buscar
          :onFiltrar="filtrar"
          class="d-flex justify-content-end"
          v-if="visible"
          :opened=true
        />
      </div> -->
    </div>
    <div class="row">
      <div class="col-6 ">
        <label>Especialidades Disponiveis</label>
        <v-select
          @input="addEspecialidade"
          :options="especialidades"
          :reduce="(espec) => espec.id"
          label="especialidade"
        >
          <template #search="{ attributes, events }">
            <input
              :class="['vs__search rounded']"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Nenhum resultado para <em>{{ search }} </em>.
            </template>
            <em style="opacity: 0.5" v-else
              >Não encontramos nenhuma especialidade 😕.</em
            >
          </template>
        </v-select>
         
      </div>
  <div class="d-flex align-items-end"><b-button  variant="primary" @click="abrirModalEspecialidades()">
        <span class="fas fa-plus" v-b-popover.hover="popoverConfig"></span>
      </b-button></div>
     
    </div>

    <div class="row">
      <div class="col-12 ">
        <b-collapse id="collapse-1" class="mt-2" :visible="visible">
          <b-table
            striped
            hover
            responsive="sm"
            stacked="sm"
            small
            outlined
            class="shadow"
            :filter="filtro"
            :fields="campos"
            :items="especialidadesAtuais"
            :per-page="paginacao.registros_por_pagina"
            :current-page="paginacao.pagina_atual"
            :busy="loading"
            show-empty
          >
            <template #empty>
              <div class="text-center">
                Nenhuma especialidade por enquanto. 🩺
              </div>
            </template>
            <template #cell(actions)="{item}">
              <b-btn
                size="sm"
                variant="transparent"
                @click="removeEspecialidade(item.id)"
              >
                <i class="fas fa-times"></i>
              </b-btn>
            </template>
          </b-table>
          <div class="row d-flex align-items-baseline">
            <div class="col-6">
              <b-pagination
                v-model="paginacao.pagina_atual"
                :per-page="paginacao.registros_por_pagina"
                   :total-rows="refEspecialidade.length"
              >
              </b-pagination>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import EspecialidadesLib from "@/libs/EspecialidadesLib.js";
import EspecialidadesForm from "../Especialiades/EspecialidadesForm.vue";
// import Buscar from "../common/Buscar.vue";

export default {
  components: {
    // Buscar,
    EspecialidadesForm,
  },
  data() {
    return {
      filtro: "",

      especialidades: [],

      loading: false,
      campos: [
        {
          key: "especialidade",
          label: "Especialidade",

          class: "text-center",
        },
        {
          key: "actions",
          label: "",

          class: "text-center",
        },
      ],
      paginacao: {
        registros_por_pagina: 5,
        pagina_atual: 1,
      },

      visible: true,
    };
  },
  props: {
    refEspecialidade: {
      Type: Array,
      default: ()=>[],
    },
  },
  async mounted() {
    await this.carregaEspecialidades();
    await this.$forceUpdate();
    await this.$nextTick();

    // this.especialidadeRecebida = Object.assign([], this.refEspecialidade)
  },
  methods: {
    abrirModalEspecialidades() {
      this.$bvModal.show("modal-especialidades");
    },
    addEspecialidade(especialidade) {
      if (this.refEspecialidade.some((espec) => espec == especialidade)) {
        this.showToast("error", "Essa especialidade já foi adicionada!");
        return;
      }
      this.refEspecialidade.push(especialidade);
    },
    removeEspecialidade(id) {
      this.refEspecialidade.splice(
        this.refEspecialidade.findIndex((e) => e == id),
        1
      );
    },
    filtrar(filtro) {
      this.filtro = filtro;
    },
    async carregaEspecialidades() {
      try {
        this.loading = true;
        this.especialidades = await EspecialidadesLib.get();
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    popoverConfig() {
      // Both title and content specified as a function in this example
      // and will be called the each time the popover is opened
      return {
        html: true,
        title: () => {
          // Note this is called only when the popover is opened
          return 'Cadastrar Especialidade <i class="fas fa-heartbeat text-primary"> </i>';
        },
        content: () => {
          // Note this is called only when the popover is opened
          return "Não encontrou a especialidade que precisa? Clique aqui para cadastrar ela :)";
        },
      };
    },
    especialidadesAtuais() {
      return this.especialidades.filter((espec) =>
        this.refEspecialidade.includes(espec.id)
      );
    },
  },
  watch: {
    especialidadeRecebida(newValue) {
      this.$emit("especialidadeChange", newValue);
    },
  },
};
</script>

<style></style>
